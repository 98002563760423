import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import LessonPlannerService from "@/core/services/lesson-planner/lesson-planner/LessonPlannerService";
import ProgramService from "@/core/services/level/program/ProgramService";
import CoursesService from "@/core/services/courses/CoursesService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import TopicService from "@/core/services/lesson-planner/topic/TopicService";
import ActivityService from "@/core/services/lesson-planner/activity/ActivityService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LessonsService from "@/core/services/lesson-planner/lessons/LessonsService";
import ActivityContentService from "@/core/services/lesson-planner/activity-content/ActivityContentService";

const lessonService = new LessonsService();
const activityService = new ActivityService();
const topicService = new TopicService();
const gradeLevelService = new GradeLevelService();
const courseService = new CoursesService();
const programService = new ProgramService();
const lessonPlannerService = new LessonPlannerService();
const academicYearService = new AcademicYearService();
const academicClassService = new AcademicClassService();
const activityContentService = new ActivityContentService();

export const LessonPlannerMixin = {
    data() {
        return {
            search: {
                title: null,
                is_active: true
            },
            lesson_planner: null,
            programs: [],
            courses: [],
            lesson_plan: [],
            activities: [],
            academic_years: [],
            academic_classes: [],
            completed_topic_ids: [],
            completed_lesson_ids: [],
            lesson_plan_datas: [],
            topics: [],
            lessons: [],
            gradesLevels: [],
            isBusy: false,
            isLessonBusy: false,
            summaryLoading: false,
            loading: false,
            lessonSummary: null,
            page: null,
            total: null,
            perPage: null
        }
    },
    methods: {
        getSingleLesson(id) {
            this.isBusy = true;
            lessonPlannerService.show(id).then(response => {
                this.lesson_plan = response.data.lesson;
                this.getTopics();
            }).catch().finally(() => {
                this.isBusy = false;
            })
        },
        getSummary(id) {
            lessonPlannerService.getSummary(id).then(response => {
                this.lesson_plan_datas = response.data.lesson_plan;
            })
        },
        getTopics() {
            this.isLessonBusy = true;
            topicService.paginate(this.lesson_plan.id).then(response => {
                this.lesson_plan_topics = response.data.data;

                if (this.lesson_plan.current_lesson) {
                    this.getLessonSummary(this.lesson_plan.current_lesson);
                } else {
                    if (this.lesson_plan_topics && this.lesson_plan_topics.length) {
                        this.getLessonSummary(this.lesson_plan_topics[0].lessons[0].id);
                    }
                }

            }).catch(err => {
                // console.log(err)
            }).finally(() => {
                this.isLessonBusy = false;
            })
        },
        getTopicLessons(topic_id) {
            this.isBusy = true;
            lessonService
                .paginate(topic_id)
                .then(response => {
                    this.lessons = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                })
                .catch(error => {
                    // console.log(error);
                }).finally(() => {
                this.isBusy = false;
            });
        },
        getLessonSummary(topic_id) {
            this.summaryLoading = true;
            lessonService
                .getSingle(topic_id)
                .then(response => {
                    this.lessonSummary = response.data.lesson;
                    // console.log(this.lessonSummary)
                })
                .catch(error => {
                    // console.log(error);
                }).finally(() => {
                this.summaryLoading = false;
            });
        },
        markComplete(topic_id) {
            this.loading = true;
            lessonService
                .markComplete(topic_id)
                .then(response => {
                    this.__get();
                    this.getLessonSummary(topic_id);
                    this.$snotify.success('Successfully Mark Completed')
                })
                .catch(error => {
                    // console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        },
        markInComplete(topic_id) {
            this.loading = true;
            lessonService
                .markInComplete(topic_id)
                .then(response => {
                    this.__get();
                    this.getLessonSummary(topic_id);
                    this.$snotify.success('Successfully Mark InCompleted')
                })
                .catch(error => {
                    // console.log(error);
                }).finally(() => {
                this.loading = false;
            });
        },
        getPrograms() {
            programService.all().then(response => {
                this.programs = response.data;
            });
        },
        getAcademicYears() {
            academicYearService.all().then(response => {
                this.academic_years = response.data;
            });
        },

        getGrades() {
            gradeLevelService.getByProgram(this.lesson_plan.program_id).then(response => {
                this.gradesLevels = response.data;
                if (this.lesson_plan.grade_id)
                    this.getSearchedCourses()
            });
        },
        getGradesByProgramId(program_id) {
            gradeLevelService.getByProgram(program_id).then(response => {
                this.gradesLevels = response.data;
            });
        },
        getAllGrades() {
            gradeLevelService.all().then(response => {
                // console.log(response.data.data)
                this.gradesLevels = response.data.data;
            });
        },
        getAllCourses() {
            courseService.all().then(response => {
                this.courses = response.data.data;
            });
        },

        getCourses() {
            courseService.getByProgram(this.lesson_plan.program_id).then(response => {
                this.courses = response.data.courses;
            });
        },
        getClass() {
            academicClassService.getByProgramLevel(this.lesson_plan.academic_year_id, this.lesson_plan.program_id, this.lesson_plan.grade_id).then(response => {
                this.academic_classes = response.data;
                // console.log(this.academic_classes)

            });
        },
        getAllClasses() {
            academicClassService.all().then(response => {
                this.academic_classes = response.data.data;
                // console.log(this.academic_classes)

            });
        },
        __sortLesson(lesson_planner_id, lessons) {
            lessonService.sort(lesson_planner_id, lessons).then((res) => {
                this.$snotify.success("Sorted");
                this.getSingleLesson(lesson_planner_id)
            }).catch(err => {
                this.$snotify.error("Sorted Error");
            }).finally(() => {
                this.$emit("getCourseSummary");
            })
        },
        __sortTopics(lesson_planner_id) {
            topicService.sort(lesson_planner_id, this.lesson_plan_topics).then((res) => {
                this.$snotify.success("Sorted");

            }).catch(err => {
                this.$snotify.error("Sorted Error");
            }).finally(() => {
                this.$emit("getCourseSummary");
            })
        },
        __deleteTopic(lesson_planner_id, id) {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        topicService
                            .delete(lesson_planner_id, id)
                            .then(response => {
                                this.$snotify.success('Deleted Successfully')
                                this.__get();
                            })
                            .catch(error => {
                                //console.log(error);
                            });
                    }
                }
            });
        },
        __deleteAcitivityContent(id) {
            this.$confirm({
                message: `Are you sure you want to delete this item?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        activityContentService
                            .delete(id)
                            .then(response => {
                                this.$snotify.success('Deleted Successfully')
                                this.__get();
                            })
                            .catch(error => {
                                this.$snotify.error('Cannot Deleted')
                                //console.log(error);
                            });
                    }
                }
            });
        },
        __updateLessonPlan(lesson_id) {
            // console.log(this.lesson_plan)
            if (this.completed_topic_ids.length) {
                if (this.completed_topic_ids.length == 1) {
                    this.completed_topic_ids = this.completed_topic_ids[0];
                }
                this.lesson_plan.completed_topic_ids = this.completed_topic_ids;
            }
            if (this.completed_lesson_ids.length) {
                if (this.completed_lesson_ids.length == 1) {
                    this.completed_lesson_ids = this.completed_lesson_ids[0];
                }
                this.lesson_plan.completed_lesson_ids = this.completed_lesson_ids;
            }
            this.isBusy = true;
            lessonPlannerService
                .update(this.lesson_plan.id, this.lesson_plan)
                .then(response => {
                    this.isBusy = false;
                    this.$snotify.success("Information updated");
                    this.getSingleLesson(lesson_id);
                })
                .catch(error => {
                    this.isBusy = false;
                    this.$snotify.error("Something Went Wrong");
                    // console.log(error);
                });
        },
        __deleteTopicLesson(lesson_planner_id, id) {
            this.$confirm({
                message: `Are you sure you want to delete this item?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        lessonService
                            .delete(lesson_planner_id, id)
                            .then(response => {
                                this.$snotify.success('Information deleted')
                                this.__get();
                            })
                            .catch(error => {
                                this.$snotify.error('Problem occurred')
                                //console.log(error);
                            });
                    }
                }
            });
        },

        checkType(type) {
            switch (type) {
                case 'assignment':
                    this.label = 'assignment';
                    return 'assignment';
                    break;
                case 'video':
                    this.label = 'Video Url';
                    return 'url';
                    break;
                case 'image':
                    this.label = 'Upload Image';
                    return 'file';
                    break;
                case 'external_links':
                    this.label = 'External Links';
                    return 'url';
                    break;
                case 'book_url':
                    this.label = 'Book Url';
                    return 'url';
                    break;
                case 'pdf':
                    this.label = 'PDF';
                    return 'file';
                    break;
                case 'ppt':
                    this.label = 'PPT';
                    return 'file';
                    break;
                case 'excel':
                    this.label = 'Excel';
                    return 'file';
                    break;
                case 'csv':
                    this.label = 'Csv';
                    return 'file';
                    break;
                default :
                    this.label = "Image";
                    return 'file'
            }
        }
    },
}
